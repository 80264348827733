import React, { useState, useRef } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/Layout';
import ProductTabs from '../components/Products/ProductTabs';
import SEO from '../components/Seo';
import ReactMarkdown from 'react-markdown';
import Protect from 'react-app-protect';

const ProductsComparisonTemplate = (props) => {
  if (!props) return null;

  const node_locale = props.pageContext.node_locale;
  const translation = props.pageContext.translation;

  const pageContent = props.pageContext.data;
  const footerContent = props.pageContext.footer.node;
  const headerContent = props.pageContext.header.node;

  const localSeo = props.pageContext.data.seo && props.pageContext.data.seo;
  const slug = props.pageContext.data.slug;

  return (
    <>
      {typeof window !== 'undefined' && (
        <Protect sha512="5F8A01670BDECBAFD1D1D5D23E3CB2205B65FE9BABA76542EDB8B36EDD69215E8042015790D3ED2C1A6E3CB949966991C8BECF34EEB02ABA9FF1EDE860EB3E38">
          <Layout
            themeColor="mode--light"
            currentLocation={props.path}
            currentNodeLocale={node_locale}
            footerContent={footerContent}
            headerContent={headerContent}
            translation={translation}
          >
            {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}
            <section className="section">
              <div className="container table">
                <div className="section__heading text-center">
                  {documentToReactComponents(pageContent.heading.json)}
                </div>
                <div>
                  <ProductTabs productsInfo={pageContent.products} />
                </div>
              </div>
            </section>
            <section className="section">
              <div className="container">
                <div className="section__heading ">
                  <ReactMarkdown
                    source={pageContent.productComparisonMatrixHeading.childMarkdownRemark.rawMarkdownBody}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed={true}
                  />
                </div>
                <div className="product-comparison">
                  <ProductTabs
                    productsInfo={pageContent.products}
                    productCompare={true}
                    featuresHeading={pageContent.featuresHeading}
                  />
                </div>
              </div>
            </section>
          </Layout>
        </Protect>
      )}
    </>
  );
};

export default ProductsComparisonTemplate;
